:root {
  --background-gradient-primary: linear-gradient(
    322deg,
    deepskyblue,
    blue,
    #e77205
  );
}

html {
  height: 100%;
  width: 100%;
  background-image: var(--background-gradient-primary);
}

body {
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* @font-face {
  font-family: 'Pacifico';
  font-style: normal;
  font-weight: 400;
  src: local('Pacifico Regular'), local('Pacifico-Regular'),
    url(https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2)
      format('woff2');
  font-display: swap;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hidden {
  display: none;
}

.hover-zoom:hover,
.hover-zoom:active {
  transform: scale(1.25);
}

.social-text {
  font-family: Arial, Verdana, Helvetica;
}

.inline-block {
  display: inline-block;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
